body {
  font-family: dinosaur, sans-serif;
  font-weight: 400;
  background: #282c34;
  color: antiquewhite;
  font-size: 24px;
}
#root {
  width: 100vw; height: 100vh;
}
*, *:after, *:before {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}
.App {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100%; }
  .App > div {
    max-width: 650px;
    text-align: left;
    margin: 1em;
    margin-top: -1em; }
    .App > div > .file-icons {
      display: flex;
      justify-content: space-between;
      margin-top: 2em;
      flex-wrap: wrap;
      align-items: center;
      align-content: center; }
      .App > div > .file-icons > a {
        display: flex;
        white-space: nowrap;
        border-radius: 0.5em;
        margin-bottom: 1em;
        padding: 0.5em; }
        .App > div > .file-icons > a > span {
          display: inline-block;
          margin-right: 0.5em; }
        .App > div > .file-icons > a > div {
          padding-right: 1em; }
        .App > div > .file-icons > a:hover {
          color: #282c34;
          background: antiquewhite; }

h1 {
  font-family: dinosaur, sans-serif;
  font-weight: 900;
  font-style: italic;
  margin: 0;
  margin-bottom: 1em; }

