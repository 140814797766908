.App {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100%;
  > div {
    max-width: 650px;
    text-align: left;
    margin: 1em;
    margin-top: -1em;
    > .file-icons {
      display: flex;
      justify-content: space-between;
      margin-top: 2em;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;
      > a {
        display: flex;
        white-space: nowrap;
        border-radius: 0.5em;
        margin-bottom: 1em;
        padding: 0.5em;
        > span {
          display: inline-block;
          margin-right: 0.5em;
        }
        > div {
          padding-right: 1em;
        }
        &:hover {
          color: #282c34;
          background: antiquewhite;
        }
      }
    }
  }
}
h1 {
  font-family: dinosaur, sans-serif;
  font-weight: 900;
  font-style: italic;
  margin: 0;
  margin-bottom: 1em;
}