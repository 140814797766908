body {
  font-family: dinosaur, sans-serif;
  font-weight: 400;
  background: #282c34;
  color: antiquewhite;
  font-size: 24px;
}
#root {
  width: 100vw; height: 100vh;
}
*, *:after, *:before {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}